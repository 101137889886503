const commonSampleFilePath = "http://localhost:3000/images/sample";

const configSampleFilesTest = {
  employees:
    "https://mlstestapi.dosystemsinc.com/images/sample_employees_bulk_upload_file.csv",
  activities:
    "https://mlstestapi.dosystemsinc.com/images/sample_activities_bulk_upload_file.csv",
  emailtemplates:
    "https://mlstestapi.dosystemsinc.com/images/sample_emailtemplates_bulk_upload_file.csv",
  roles:
    "https://mlstestapi.dosystemsinc.com/images/sample_roles_bulk_upload_file.csv",
  uploadhistories:
    "https://mlstestapi.dosystemsinc.com/images/sample_uploadhistories_bulk_upload_file.csv",
  emailstatuses:
    "https://mlstestapi.dosystemsinc.com/images/sample_emailstatuses_bulk_upload_file.csv",
  myProperties:
    "https://mlstestapi.dosystemsinc.com/images/sample_myproperties_bulk_upload_file.csv",
  clients:
    "https://mlstestapi.dosystemsinc.com/images/sample_clients_bulk_upload_file.csv",
  searches:
    "https://mlstestapi.dosystemsinc.com/images/sample_searches_bulk_upload_file.csv",
  matchedproperties:
    "https://mlstestapi.dosystemsinc.com/images/sample_matchedproperties_bulk_upload_file.csv",
  savedSearches:
    "https://mlstestapi.dosystemsinc.com/images/sample_savedsearches_bulk_upload_file.csv",
  offices:
    "https://mlstestapi.dosystemsinc.com/images/sample_offices_bulk_upload_file.csv",
  agents:
    "https://mlstestapi.dosystemsinc.com/images/sample_agents_bulk_upload_file.csv",
  tickets:
    "https://mlstestapi.dosystemsinc.com/images/sample_tickets_bulk_upload_file.csv",
  recentmatchedproperties:
    "https://mlstestapi.dosystemsinc.com/images/sample_recentmatchedproperties_bulk_upload_file.csv",
  showmatchedproperties:
    "https://mlstestapi.dosystemsinc.com/images/sample_showmatchedproperties_bulk_upload_file.csv",
  reminders:
    "https://mlstestapi.dosystemsinc.com/images/sample_reminders_bulk_upload_file.csv",
};

const configSampleFilesDev = {
  employees:
    "https://mlsdevapi.dosystemsinc.com/images/employee.xlsx",
  activities:
    "https://mlsdevapi.dosystemsinc.com/images/sample_activities_bulk_upload_file.csv",
  emailtemplates:
    "https://mlsdevapi.dosystemsinc.com/images/sample_emailtemplates_bulk_upload_file.csv",
  roles:
    "https://mlsdevapi.dosystemsinc.com/images/sample_roles_bulk_upload_file.csv",
  uploadhistories:
    "https://mlsdevapi.dosystemsinc.com/images/sample_uploadhistories_bulk_upload_file.csv",
  emailstatuses:
    "https://mlsdevapi.dosystemsinc.com/images/sample_emailstatuses_bulk_upload_file.csv",
  myProperties:
    "https://mlsdevapi.dosystemsinc.com/images/property.xlsx",
  clients:
    "https://mlsdevapi.dosystemsinc.com/images/client.xlsx",
  searches:
    "https://mlsdevapi.dosystemsinc.com/images/sample_searches_bulk_upload_file.csv",
  matchedproperties:
    "https://mlsdevapi.dosystemsinc.com/images/sample_matchedproperties_bulk_upload_file.csv",
  savedSearches:
    "https://mlsdevapi.dosystemsinc.com/images/savedsearch.xlsx",
  offices:
    "https://mlsdevapi.dosystemsinc.com/images/sample_offices_bulk_upload_file.csv",
  agents:
    "https://mlsdevapi.dosystemsinc.com/images/agent.xlsx",
  tickets:
    "https://mlsdevapi.dosystemsinc.com/images/tickets.xlsx",
  recentmatchedproperties:
    "https://mlsdevapi.dosystemsinc.com/images/sample_recentmatchedproperties_bulk_upload_file.csv",
  showmatchedproperties:
    "https://mlsdevapi.dosystemsinc.com/images/sample_showmatchedproperties_bulk_upload_file.csv",
  reminders:
    "https://mlsdevapi.dosystemsinc.com/images/reminders.xlsx",
};

const configSampleFilesLive = {
  employees:
    "https://mlsliveapi.dosystemsinc.com/images/sample_employees_bulk_upload_file.csv",
  activities:
    "https://mlsliveapi.dosystemsinc.com/images/sample_activities_bulk_upload_file.csv",
  emailtemplates:
    "https://mlsliveapi.dosystemsinc.com/images/sample_emailtemplates_bulk_upload_file.csv",
  roles:
    "https://mlsliveapi.dosystemsinc.com/images/sample_roles_bulk_upload_file.csv",
  uploadhistories:
    "https://mlsliveapi.dosystemsinc.com/images/sample_uploadhistories_bulk_upload_file.csv",
  emailstatuses:
    "https://mlsliveapi.dosystemsinc.com/images/sample_emailstatuses_bulk_upload_file.csv",
  myProperties:
    "https://mlsliveapi.dosystemsinc.com/images/sample_myproperties_bulk_upload_file.csv",
  clients:
    "https://mlsliveapi.dosystemsinc.com/images/sample_clients_bulk_upload_file.csv",
  searches:
    "https://mlsliveapi.dosystemsinc.com/images/sample_searches_bulk_upload_file.csv",
  matchedproperties:
    "https://mlsliveapi.dosystemsinc.com/images/sample_matchedproperties_bulk_upload_file.csv",
  savedSearches:
    "https://mlsliveapi.dosystemsinc.com/images/sample_savedsearches_bulk_upload_file.csv",
  offices:
    "https://mlsliveapi.dosystemsinc.com/images/sample_offices_bulk_upload_file.csv",
  agents:
    "https://mlsliveapi.dosystemsinc.com/images/sample_agents_bulk_upload_file.csv",
  tickets:
    "https://mlsliveapi.dosystemsinc.com/images/sample_tickets_bulk_upload_file.csv",
  recentmatchedproperties:
    "https://mlsliveapi.dosystemsinc.com/images/sample_recentmatchedproperties_bulk_upload_file.csv",
  showmatchedproperties:
    "https://mlsliveapi.dosystemsinc.com/images/sample_showmatchedproperties_bulk_upload_file.csv",
  reminders:
    "https://mlsliveapi.dosystemsinc.com/images/sample_reminders_bulk_upload_file.csv",
};

export default configSampleFilesTest;
//export default configSampleFilesLive;
// export default configSampleFilesDev;
